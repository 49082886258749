import React, { Component, Fragment } from "react";
import { withRouter , Link} from "react-router-dom";
import { connect } from 'react-redux';
import { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem } from "../../providers/actions";
import { mostraAguardando, escondeAguardando } from "../../providers/actions";
import NumberFormat from 'react-number-format';
import { validaCamposNumericos } from "../../utils/formValidationUtil";
import { Message } from "../Message/Message";
import { Loading } from "../Loading/Loading";
import { ConfirmModal } from "../ConfirmModal/ConfirmModal";
import { getMessagemErro } from "../../utils/erro";
import { TIPO_SOLICITACAO_ADESAO_INCLUSAO, TIPO_SOLICITACAO_ADESAO_ALTERACAO, ID_TIPO_SOLICITACAO_ADESAO_ALTERACAO } from "../../providers/constTiposSolicitacaoAdesao";
import { ID_SITUACAO_SOLICITACAO_ADESAO_REJEITADA_CEDENTE } from "../../providers/constSituacoesSolicitacaoAdesao";
import api from "../../services/api";
import './SolicitacaoAdesao.css';
import { SolicitacaoAdesaoAttachmentsAndHistory } from "./AttachmentsAndHistory";

const mapDispatchToProps = { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem, mostraAguardando, escondeAguardando }

export const SolicitacaoAdesaoEdit =

withRouter(

// redux
connect(
	(null), 
	(mapDispatchToProps)
)(

	class extends Component {

		constructor(props){
			super(props);
			this.state = {
				solicitacao: {
					id: '',
					numero: '',
					orgao: '',
					fornecedora: '',
					tipo: '',
					nomeTipo: '',
					situacao: '',
					nomeSituacao: '',
					cpfDirigenteTi: '',
					cpfResponsavelTecnico: '',
					finalidade: '',
					justificativaAlteracaoVolumetria: '',
					volumetriaAtual: '',
					volumetriaSolicitada: '',
					alteracaoPermitida: true,
				},
				detalhesFornecedora: null,
				adesaoFornecedora: null,
				fornecedoras: [],
				dirigentesTi: [],
				responsaveisTecnicos: [],
				tiposSolicitacao: [TIPO_SOLICITACAO_ADESAO_INCLUSAO, TIPO_SOLICITACAO_ADESAO_ALTERACAO],
				textoPadraoFinalidade: '',
				carregandoFornecedoras: false,
				carregandoDirigentesTi: false,
				carregandoResponsaveisTecnicos: false,
				carregandoDetalhesFornecedora: false,
				carregandoAdesaoFornecedora: false,
				exibeModalModeloFinalidade: false,
				botoesHabilitados : true,
				dataHoraCarregamentoPagina: new Date(),
			};
			this.handleInputChange = this.handleInputChange.bind(this);
			this.handleInputNumberChange = this.handleInputNumberChange.bind(this);
			this.handleFornecedoraChange = this.handleFornecedoraChange.bind(this);
			this.carregarDirigentesTi = this.carregarDirigentesTi.bind(this);
			this.carregarResponsaveisTecnicos = this.carregarResponsaveisTecnicos.bind(this);
			this.carregaFornecedorasDisponiveisAdesao = this.carregaFornecedorasDisponiveisAdesao.bind(this);
			this.carregaTextoPadraoFinalidade = this.carregaTextoPadraoFinalidade.bind(this);
			this.fechaModal = this.fechaModal.bind(this);
			this.salvar = this.salvar.bind(this);
			this.novo = this.novo.bind(this);
		}

		render() {
			return (
				<main id="content" className="page-content p-3">
					<Message />
					<Loading />
					<div style={{margin: "10px"}}>
						<h1>Solicitação de Adesão e Alteração de Volumetria dos Órgãos</h1>
					</div>
					<div className="div-form">
						{this.renderForm()}
					</div>
				</main>
			);
		}

		componentDidMount(){
			this.props.limpaMensagem();
			const id = this.props.match.params.id;

			if(id){
				api.get(`/api/solicitacao-adesao/${id}`)
					.then(response => { 
						const solicitacao = response.data;
						this.setState({ solicitacao }) 
						this.carregarDirigentesTi();
						this.carregarResponsaveisTecnicos();
						this.carregaInformacoesFornecedora(solicitacao.fornecedora);
					})
					.catch(erro => {
						const msg = getMessagemErro("Não foi possível recuperar a solicitação de adesão.", erro);
						console.log(JSON.stringify(msg));
						this.props.exibeMensagemErro(msg.mensagem, msg.erros);
					});
			} else {
				this.carregarDirigentesTi();
				this.carregarResponsaveisTecnicos();
			}

			this.setState({
				carregandoFornecedoras: true,
			})
			this.carregaFornecedorasDisponiveisAdesao();
			this.carregaTextoPadraoFinalidade();
		}

		renderForm() {
			const { solicitacao, detalhesFornecedora, adesaoFornecedora, textoPadraoFinalidade } = this.state;

			return(
				<div className="container-fluid">

					<ConfirmModal titulo="Confirmação" 
						visible={this.state.exibeModalModeloFinalidade}
						handleOk={() => this.copiaModeloFinalidade()}
						handleCancel={() => this.fechaModal()}
						okText='Sim'
						cancelText='Não'
						width='600'
						message={"Esse é o conteúdo que deve ser contemplado no texto da finalidade. Deseja copiá-lo?"}>
							
							<div className="br-form">
								<div className="row">
									<div className="col">
										<div className="field">
											<div className="input">
												<textarea value={textoPadraoFinalidade} className="textarea-texto-padro-finalidade" readOnly/>
											</div>
										</div>
									</div>
								</div>
							</div>

					</ConfirmModal>

					<div className="br-form">
						{ solicitacao.id &&
							<div className="row">
								<div className="col-md">
									<div className="field">
										<div className="input">
											<label>Número:</label>
											<input value={solicitacao.numero} readOnly/>
										</div>
									</div>
								</div>
								<div className="col-md">
									<div className="field">
										<div className="input">
											<label>Situação:</label>
											<input value={solicitacao.nomeSituacao} readOnly/>
										</div>
									</div>
								</div>
							</div>
						}
						<div className="row">
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label>API Necessária:</label>
										{ this.state.fornecedoras.length > 0 && 
											<select name="fornecedora" onChange={this.handleFornecedoraChange} value={solicitacao.fornecedora} disabled={solicitacao.id}>
												<option value="">Selecione</option>
													{
														this.state.fornecedoras?.map(fornecedora =>
															<option 
																key={fornecedora.id} 
																value={fornecedora.id}>
																{fornecedora.nome}
															</option>
													)}
											</select>
										}
										{ this.state.carregandoFornecedoras === true  && 
											<button className="br-button is-secondary is-circle is-loading" type="button"/>
										}
										{this.state.carregandoFornecedoras === false && this.state.fornecedoras.length === 0 && "Não há API's disponíveis para adesão."}
									</div>
								</div>
							</div>

							{solicitacao.fornecedora &&
								<Fragment>
									<div className="col-md">
										<div className="field">
											<div className="input">
												<label>Órgão cedente dos dados:</label>
												{detalhesFornecedora &&
													<input value={detalhesFornecedora.nomeOrgao} className="input-bold" readOnly/>
												}
												{ this.state.carregandoDetalhesFornecedora === true  && 
													<button className="br-button is-secondary is-circle is-loading" type="button"/>
												}
											</div>
										</div>
									</div>
									{ detalhesFornecedora && detalhesFornecedora.descricaoComplementar && 
										<div className="col-md">
											<div className="field">
												<div className="input">
													<label>Descrição da API:</label>
													<textarea value={detalhesFornecedora.descricaoComplementar} className="input-bold" readOnly={true} style={{height:"90px"}}/>
												</div>
											</div>
										</div>
									}
								</Fragment>
							}
						</div>
						{solicitacao.fornecedora &&
							<Fragment>
								<div className="row">
									<div className="col-md">
										<div className="field">
											<div className="input">
												<label>Dirigente de TI:</label>
												{ this.state.dirigentesTi.length > 0 && 
													<select name="cpfDirigenteTi" onChange={this.handleInputChange} value={solicitacao.cpfDirigenteTi}>
														<option value="">Selecione</option>
															{
																this.state.dirigentesTi?.map(dirigenteTi =>
																	<option 
																		key={dirigenteTi.cpf} 
																		value={dirigenteTi.cpf}>
																		{dirigenteTi.cpf} - {dirigenteTi.nome}
																	</option>
															)}
													</select>
												}
												{this.state.carregandoDirigentesTi === true && 
													<button className="br-button is-secondary is-circle is-loading" type="button"/>
												}
												{this.state.carregandoDirigentesTi === false && this.state.dirigentesTi.length === 0 && "Não há dirigentes de TI cadastrados no órgão."}
											</div>
										</div>
									</div>
									<div className="col-md">
										<div className="field">
											<div className="input">
												<label>Responsável Técnico:</label>
												{ this.state.responsaveisTecnicos.length > 0 && 
													<select name="cpfResponsavelTecnico" onChange={this.handleInputChange} value={solicitacao.cpfResponsavelTecnico}>
														<option value="">Selecione</option>
															{
																this.state.responsaveisTecnicos?.map(responsavelTecnico =>
																	<option 
																		key={responsavelTecnico.cpf} 
																		value={responsavelTecnico.cpf}>
																		{responsavelTecnico.cpf} - {responsavelTecnico.nome}
																	</option>
															)}
													</select>
												}
												{this.state.carregandoResponsaveisTecnicos === true && 
													<button className="br-button is-secondary is-circle is-loading" type="button"/>
												}
												{this.state.carregandoResponsaveisTecnicos === false && this.state.responsaveisTecnicos.length === 0 && "Não há responsáveis técnicos cadastrados no órgão."}
											</div>
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col-sd">
										<div className="field">
											<div className="input">
												<label>Tipo de Solicitação:</label>
												{solicitacao.tipo && 
													<input value={solicitacao.nomeTipo} readOnly/>
												}
												{ this.state.carregandoAdesaoFornecedora === true && 
													<button className="br-button is-secondary is-circle is-loading" type="button"/>
												}
											</div>
										</div>
									</div>
									{adesaoFornecedora && solicitacao.volumetriaAtual > 0 &&
										<div className="col-sd">
											<div className="field">
												<div className="input">
													<label>Volumetria Atual:</label>
													<NumberFormat value={solicitacao.volumetriaAtual} 
														thousandSeparator={'.'} 
														decimalSeparator={','} 
														decimalScale={0}
														allowNegative={false}
														isNumericString={true}
														className="texto-alinhado-direita"
														readOnly
														/>
												</div>
											</div>
										</div>
									}
									<div className="col-sd">
										<div className="field">
											<div className="input">
												<label>Volumetria Anual Solicitada:</label>
												<NumberFormat value={solicitacao.volumetriaSolicitada} 
													thousandSeparator={'.'} 
													decimalSeparator={','} 
													decimalScale={0}
													allowNegative={false}
													isNumericString={true}
													className="texto-alinhado-direita"
													onValueChange={(valoresInput) => this.handleInputNumberChange('volumetriaSolicitada', valoresInput)}
													/>
											</div>
										</div>
									</div>
								</div>

								{solicitacao.tipo === ID_TIPO_SOLICITACAO_ADESAO_ALTERACAO &&
									<div className="row">
										<div className="col-md">  
											<div className="field">
												<div className="input"> 
													<label htmlFor="justificativaAlteracaoVolumetria">
														Justificativa para alteração de volumetria:
													</label>
													<textarea name="justificativaAlteracaoVolumetria" value={solicitacao.justificativaAlteracaoVolumetria} onChange={this.handleInputChange} maxLength={255} style={{height:"300px"}}/>
												</div>
											</div>
										</div>
									</div>
								}

								<div className="row">
									<div className="col-md">  
										<div className="field">
											<div className="input"> 
												<label htmlFor="finalidade">
													Finalidade:
													<button type="button" className="ajuda-button" title="Instruções para o preenchimento do campo finalidade" onClick={() => this.setState({exibeModalModeloFinalidade: true})}>
														<span className="sr-only">Ajuda para preenchimento do campo finalidade</span>
														<i className="fas fa-question"></i>
													</button>
												</label>
												<textarea name="finalidade" value={solicitacao.finalidade} onChange={this.handleInputChange} maxLength={3000} style={{height:"300px"}}/>
											</div>
										</div>
									</div>
								</div>
								{ Number(solicitacao.situacao) === ID_SITUACAO_SOLICITACAO_ADESAO_REJEITADA_CEDENTE &&
									<div className="row">
										<div className="col-md">
											<div className="field">
												<div className="input">
													<label>Justificativa do cedente dos dados:</label>
													<textarea value={solicitacao.justificativaOrgaoFornecedor} readOnly={true} style={{height:"90px"}}/>
												</div>
											</div>
										</div>
									</div> 
								}

								{solicitacao.id &&
									<SolicitacaoAdesaoAttachmentsAndHistory solicitacao={solicitacao} habilitaEdicaoAnexo={true} keyHistory={this.state.dataHoraCarregamentoPagina}/>
								}

								{! solicitacao.id && 
									<div className="row">
										<div className="col-md">
											<span style={{color: '#C9302C'}}>
											* Os anexos ficarão disponíveis para download e upload após a inclusão da solicitação. 
											</span>
										</div>
									</div> 
								}
							</Fragment>
						}

						{/* button actions */}
						<div className="actions-button">
							<div className="actions justify-content-start">
								{this.state.solicitacao.alteracaoPermitida && 
									<button type="button" className="button is-primary" onClick={(e) => this.salvar(e, false)} disabled={!this.state.botoesHabilitados}>{solicitacao.id ? 'Alterar' : 'Incluir'}</button>
								}

								{solicitacao.liberacaoAssinaturaPermitida &&
									<button type="button" className="button is-secondary" onClick={(e) => this.salvar(e, true)} disabled={!this.state.botoesHabilitados}>Liberar para Assinatura</button>
								}	

								{this.state.solicitacao.assinaturaPermitida && 
									<Link to={`/solicitacao-adesao/sign/${solicitacao.id}`}>
										<button type="button" className="button is-secondary botaoNovo" onClick={this.assinar} disabled={!this.state.botoesHabilitados}>Assinar</button>
										</Link>
								}

								{this.state.solicitacao.id && 
									<button type="button" className="button is-secondary botaoNovo" onClick={this.novo} disabled={!this.state.botoesHabilitados}>Nova Solicitação</button>
								}

								<Link to="/"> 
									<div className="back-button">
										<button type="button" className="button is-secondary" disabled={!this.state.botoesHabilitados}>Voltar</button>
									</div>
								</Link>
							</div>
						</div>
					</div>
				</div>
				
			);
		}

		validaFormulario(){
			const limiteVolumetria = 2147483647;

			let mensagens = [];
			const { solicitacao } = this.state;
			const { fornecedora, cpfDirigenteTi, cpfResponsavelTecnico, tipo, volumetriaSolicitada, finalidade, justificativaAlteracaoVolumetria } = solicitacao;

			if(!fornecedora){
				mensagens.push({mensagem: "API necessária é obrigatória."})
			} else {

				if(!cpfDirigenteTi){
					mensagens.push({mensagem: "Dirigente de TI obrigatório."})
				}

				if(!cpfResponsavelTecnico){
					mensagens.push({mensagem: "Responsável técnico é obrigatório."})
				}

				if(!tipo){
					mensagens.push({mensagem: "Tipo de solicitação é obrigatório."})
				} else {
					if(tipo === ID_TIPO_SOLICITACAO_ADESAO_ALTERACAO && (!justificativaAlteracaoVolumetria || justificativaAlteracaoVolumetria.trim().length === 0)){
						mensagens.push({mensagem: "Justificativa para alteração de volumetria é obrigatória."})
					}
				}

				validaCamposNumericos(mensagens, volumetriaSolicitada, "Volumetria anual solicitada", true, false, true, limiteVolumetria);

				if(!finalidade || finalidade.trim().length === 0 ){
					mensagens.push({mensagem: "Finalidade é obrigatória."})
				}
			}
		
			if(mensagens.length > 0){
				this.props.exibeMensagemErro("Não foi possível realizar a operação porque há inconsistências nos campos informados:", mensagens)
				throw new Error("Formulário não preenchido corretamente.");
			}
		}

		handleInputChange(evento){
			const target = evento.target;
			const nome = target.name;
			const valor = target.type === 'checkbox' ? target.checked : target.value;

			const solicitacao = this.state.solicitacao;
			solicitacao[nome] = valor;
			this.setState({ solicitacao });
		}

		handleFornecedoraChange(evento){
			const fornecedora = evento.target.value;
			const { solicitacao }  = this.state;
			solicitacao.fornecedora = fornecedora;
			solicitacao.tipo = '';
			solicitacao.nomeTipo = '';
			solicitacao.finalidade = '';
			solicitacao.justificativaAlteracaoVolumetria = '';
			this.setState({ solicitacao });
			this.carregaInformacoesFornecedora(fornecedora);
		}

		handleInputNumberChange(nomePropriedade, valoresInput){
			const solicitacao = this.state.solicitacao;
			solicitacao[nomePropriedade] = Number(valoresInput.value);
			this.setState({ solicitacao });
		}

		carregaFornecedorasDisponiveisAdesao() {
			api.get('/api/aplicacao-fornecedora/disponivel-adesao-orgao')
				.then(response => { 
					this.setState({fornecedoras: response.data }) 
				})
				.catch(erro => {
					const msg = getMessagemErro("Não foi possível recuperar as API's.", erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
				})
				.finally(() => {
					this.setState({carregandoFornecedoras : false});
				});
		}

		carregaInformacoesFornecedora(fornecedora) {
			this.setState({detalhesFornecedora: null, adesaoFornecedora: null});
			
			if(fornecedora) {
				this.setState({carregandoDetalhesFornecedora: true, carregandoAdesaoFornecedora: true});
				api.get(`/api/aplicacao-fornecedora/informacoes-complementares/${fornecedora}`)
					.then(response => { 
						this.setState({detalhesFornecedora: response.data});
					})
					.catch(erro => {
						const msg = getMessagemErro("Não foi possível recuperar os detalhes da API.", erro);
						console.log(JSON.stringify(msg));
						this.props.exibeMensagemErro(msg.mensagem, msg.erros);
					})
					.finally(()=> {
						this.setState({carregandoDetalhesFornecedora: false})
					});

				api.get(`/api/orgao/adesao/fornecedora/${fornecedora}`)
					.then(response => { 
						const adesaoFornecedora = response.data;
						const solicitacao = this.state.solicitacao;
						solicitacao.tipo = TIPO_SOLICITACAO_ADESAO_ALTERACAO.codigo;
						solicitacao.nomeTipo = TIPO_SOLICITACAO_ADESAO_ALTERACAO.nome;
						solicitacao.volumetriaAtual = adesaoFornecedora.limite;
						if(! solicitacao.id) {
							solicitacao.finalidade = adesaoFornecedora.finalidade;
						}
						this.setState({adesaoFornecedora: response.data, solicitacao});
					})
					.catch(erro => {
						if(erro.status === 404) {
							const adesaoFornecedora = {}
							const solicitacao = this.state.solicitacao;
							solicitacao.tipo = TIPO_SOLICITACAO_ADESAO_INCLUSAO.codigo;
							solicitacao.nomeTipo = TIPO_SOLICITACAO_ADESAO_INCLUSAO.nome;
							solicitacao.volumetriaAtual = 0;
							this.setState({adesaoFornecedora, solicitacao});
						} else {
							const msg = getMessagemErro("Não foi possível recuperar a adesão do órgão para a API.", erro);
							console.log(JSON.stringify(msg));
							this.props.exibeMensagemErro(msg.mensagem, msg.erros);
						}
					})
					.finally(()=> {
						this.setState({carregandoAdesaoFornecedora: false})
					});
			}
		}

		carregarDirigentesTi() {
			this.setState({
				carregandoDirigentesTi: true,
			});
			api.get('/api/gestor/dirigente-ti')
				.then(response => { 
					const dirigentesTi = response.data;
					const solicitacao = this.state.solicitacao;
					if (dirigentesTi.length === 1){
						solicitacao.cpfDirigenteTi = dirigentesTi[0].cpf
					}

					this.setState({dirigentesTi, solicitacao }) 
				})
				.catch(erro => {
					const msg = getMessagemErro("Não foi possível recuperar os dirigentes de TI.", erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
				}).finally(() => {
					this.setState({carregandoDirigentesTi : false});
				});
		}

		carregarResponsaveisTecnicos() {
			this.setState({
				carregandoResponsaveisTecnicos: true,
			});
			api.get('/api/gestor/responsavel-tecnico')
				.then(response => { 
					this.setState({responsaveisTecnicos: response.data }) 
					const responsaveisTecnicos = response.data;
					const solicitacao = this.state.solicitacao;
					if (responsaveisTecnicos.length === 1){
						solicitacao.cpfResponsavelTecnico = responsaveisTecnicos[0].cpf
					}

					this.setState({ responsaveisTecnicos, solicitacao })
				})
				.catch(erro => {
					const msg = getMessagemErro("Não foi possível recuperar os responsáveis técnicos.", erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
				}).finally(() => {
					this.setState({carregandoResponsaveisTecnicos : false});
				});
		}

		carregaTextoPadraoFinalidade() {
			api.get('/api/solicitacao-adesao/texto-padrao-finalidade')
				.then(response => { 
					this.setState({textoPadraoFinalidade: response.data['textoPadraoFinalidade'] }) 
				})
				.catch(erro => {
					const msg = getMessagemErro("Não foi possível recuperar o texto padrão de finalidade.", erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
				}).finally(() => {
					this.setState({carregandoResponsaveisTecnicos : false});
				});
		}

		novo(e){
			const rotaParaNovo = "/solicitacao-adesao/new";
			if (rotaParaNovo === this.props.location.pathname) {
				this.props.history.push("/");
				this.props.history.goBack();
			} else {
				this.props.history.push(rotaParaNovo);
			}
		}

		salvar (e, liberarParaAssinatura){
			this.props.limpaMensagem();
			const { solicitacao } = this.state;

			try{
				this.validaFormulario();
			}catch(erroValidacao){
				e.preventDefault();
				return;
			}

			const method = solicitacao.id ? 'put' : 'post';
			let url = '/api/solicitacao-adesao';
			if(solicitacao.id){
				url = url + '/' + solicitacao.id;
			}

			solicitacao.liberarParaAssinatura = liberarParaAssinatura;

			const options = {
				method,
				url,
				data: solicitacao
			}

			this.props.mostraAguardando();
			this.setState({botoesHabilitados : false});
			api(options)
				.then(response => { 
					let operacao = 'incluída';
					if(this.state.solicitacao.id) {
						if(liberarParaAssinatura) {
							operacao = 'liberada para assinatura';
						} else {
							operacao = 'alterada';
						}
					}
					const mensagem = `Solicitação de adesão ${operacao} com sucesso!`;
					const solicitacao = response.data;
			
					this.setState({ 
						solicitacao,
						dataHoraCarregamentoPagina: new Date(),
					});
					this.props.exibeMensagemSucesso(mensagem);
				})
				.catch(erro => {
					const mensagemInicial = `Não foi possível ${this.state.solicitacao.id ? 'alterar' : 'incluir'} a solicitação de adesão.`;
					const msg = getMessagemErro(mensagemInicial, erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
				})
				.then(() => {
					this.props.escondeAguardando();
					this.setState({botoesHabilitados : true});
				});
		}

		copiaModeloFinalidade() {
			const { solicitacao, textoPadraoFinalidade} = this.state;
			solicitacao.finalidade = textoPadraoFinalidade;
			this.setState({solicitacao});
			this.fechaModal();
		}

		fechaModal () {
			this.setState(
				{
					exibeModalModeloFinalidade: false,
				}
			);
		}
	
	}

) // redux
); // router