import React, { Component } from "react";
import { withRouter, Link} from "react-router-dom";
import { connect } from 'react-redux';
import { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem } from "../../providers/actions";
import { mostraAguardando } from "../../providers/actions";
import { Message } from "../Message/Message";
import { Loading } from "../Loading/Loading";
import { ManagerEditSiape } from "./EditSiape";
import { ManagerEditNotSiape } from "./EditNotSiape";
import {getMessagemErro} from "../../utils/erro";
import api from "../../services/api";
import './Manager.css';
import { Picky } from 'react-picky';
import 'react-picky/dist/picky.css';
import {DIRIGENTE_TI, RESPONSAVEL_TECNICO} from '../../providers/constAtribuicoesGestor';

const mapDispatchToProps = { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem, mostraAguardando }

const mapStateToProps = (state) => {
	return {
		user: state.usuarioStore.user,
	}
}

export const ManagerEdit =

withRouter(

// redux
connect(
	(mapStateToProps), 
	(mapDispatchToProps)
)(
	class extends Component {

		constructor(props){
			super(props);
			this.state = {
				gestor: {
					cpf: "",
					nome: "",
					email: "",
					orgao: "",
					vinculadoSiape: "",
					perfilGestor: "",
					atribuicao: "",
					orgaoCliente: false,
					apisGestor: [],
				},
				orgaos: [],
				fornecedorasOrgao: [],
				perfisGestor: [],
				atribuicoesGestor: [DIRIGENTE_TI, RESPONSAVEL_TECNICO],
				edicao : false,
				botoesHabilitados : true,
				carregandoFornecedoras: false,
				carregandoPerfisGestor: false,
			};
			this.handleInputChange = this.handleInputChange.bind(this);
			this.handleOrgaoChange = this.handleOrgaoChange.bind(this);
			this.atualizaGestorSiape = this.atualizaGestorSiape.bind(this);
			this.atualizaGestorNotSiape = this.atualizaGestorNotSiape.bind(this);
			this.carregaFornecedorasOrgao = this.carregaFornecedorasOrgao.bind(this);
			this.carregaPerfisGestor = this.carregaPerfisGestor.bind(this);
			this.salvar = this.salvar.bind(this);
			this.novo = this.novo.bind(this);
		}

		render() {
			return (
				<main id="content" className="page-content p-3">
						<Message />
						<Loading />
						
						<div  style={{margin: "10px"}}>
							<h1>Cadastro de Gestores</h1>
						</div>

					<div className="div-form">
						{this.renderForm()}
					</div>
				</main>
			);
		}

		componentDidMount(){
			this.props.limpaMensagem();
			const cpf = this.props.match.params.cpf;

			if(cpf){
				this.setState({edicao : true,}) 
				api.get('/api/gestor/' + cpf)
				.then(response => { 
					const gestor = response.data;
					this.setState(
						{
							gestor,
						}) 
					this.carregaFornecedorasOrgao(gestor.orgao)
					this.carregaPerfisGestor(gestor.orgao)
				})
				.catch(erro => {
					console.log("Não foi possível recuperar o gestor " + erro)
					this.props.exibeMensagemErro("Erro durante o carregamento da página")
				});
			}else{
				this.setState({edicao : false,}) 
			}
			api.get('/api/orgao/perfil-usuario?ativos=true')
				.then(response => { this.setState({orgaos: response.data})})
				.catch(erro => {
					console.log("Não foi possível recuperar os órgãos " + erro)
					this.props.exibeMensagemErro("Erro durante o carregamento da página")
				});

		}

		renderForm() {
			const user = this.props.user;
			const gestor = this.state.gestor;

			return(
				<div className="container-fluid">
					<div className="br-form">

						<div className="row">

							{(this.state.orgaos && this.state.orgaos.length > 0) &&	
								<div className="col-md">
									<div className="field">
										<div className="input">
											<label htmlFor="orgao">Órgão:</label>						
										</div>
										<div className="input">
											<select name="orgao" onChange={this.handleOrgaoChange} value={gestor.orgao}
												disabled={user.isGestorAPI === true && gestor.apisGestor.length > 0}>
												<option value="">Selecione</option>
												{
													this.state.orgaos.map(orgao =>
														<option 
															key={orgao.id} 
															value={orgao.id}>
															{orgao.nome}
														</option>
												)}
											</select>
											&nbsp;&nbsp;
											{this.state.carregandoFornecedoras === true && 
												<button className="br-button is-secondary is-circle is-loading" type="button"/>
											}
										</div>
									</div>
								</div>
							}

							{(gestor.orgao) &&	
								<div className="col-md">
									<div className="field">
										<div className="input">
											<label htmlFor="orgao">Perfil de Gestor:</label>						
											<select name="perfilGestor" onChange={this.handleInputChange} value={gestor.perfilGestor}
												disabled={user.isGestorAPI === true && gestor.apisGestor.length > 0}>
												<option value="">Selecione</option>
												{
													this.state.perfisGestor.map(pefil =>
														<option 
															key={pefil.id} 
															value={pefil.id}>
															{pefil.nome}
														</option>
												)}
											</select>
											&nbsp;&nbsp;
											{this.state.carregandoPerfisGestor === true && 
												<button className="br-button is-secondary is-circle is-loading" type="button"/>
											}
										</div>
									</div>
								</div>
							}
						</div>

						{this.isServidorSiape() &&
							<ManagerEditSiape gestor={gestor} edicao={this.state.edicao} onAtualizaServidor={this.atualizaGestorSiape}/>
						}
						{this.isNotServidorSiape() &&
							<ManagerEditNotSiape gestor={gestor} edicao={this.state.edicao} onAtualizaServidor={this.atualizaGestorNotSiape}/>
						}

						{(user.isAdministrador && gestor.orgaoCliente && this.state.fornecedorasOrgao.length > 0) &&	
							<div className="row">
								<div className="col-md">
									<div className="field">
										<div className="input">
											<label htmlFor="fornecedoras">Gestor das API's:</label>						
										</div>
										<div>
											<Picky
												options={this.state.fornecedorasOrgao}
												value={gestor.apisGestor}
												valueKey="id"
												labelKey="nome"
												multiple={true}
												includeSelectAll={true}
												includeFilter={true}
												onChange={values => this.handlePickyChange(values)}
												selectAllText="Selecionar todos"
												placeholder="Nenhuma API selecionada"
												filterPlaceholder="Filtro..."
												keepOpen={true}
												/>
										</div>
									</div>
								</div>
							</div>
						}

						{gestor.orgaoCliente &&
							<div className="row">
								<div className="col-sd">
									<div className="field">
										<div className="input">
											<label htmlFor="atribuicao">Atribuição:</label>						
											<select name="atribuicao" onChange={this.handleInputChange} value={gestor.atribuicao}>
												<option value="">Selecione</option>
												{
													this.state.atribuicoesGestor.map(atribuicao =>
														<option 
															key={atribuicao.codigo} 
															value={atribuicao.codigo}>
															{atribuicao.nome}
														</option>
												)}
											</select>
										</div>
									</div>
								</div>
							</div>
						}

						{/* button actions */}
						<div className="actions-button">
							<div className="actions justify-content-start">
								{gestor.orgao &&
									<button type="button" className="button is-primary" onClick={this.salvar} disabled={!this.state.botoesHabilitados}>{this.state.edicao ? 'Alterar' : 'Incluir'}</button>
								}

								{this.state.edicao && 
									<button type="button" className="button is-secondary" onClick={this.novo} disabled={!this.state.botoesHabilitados}>Novo Gestor</button>
								}

								<Link to="/"> 
									<div className="back-button">
										<button type="button" className="button is-secondary" disabled={!this.state.botoesHabilitados}>Voltar</button>
									</div>
								</Link>
							</div>
						</div>

					</div>
				</div>
			);
		}

		handleInputChange(evento){
			const target = evento.target;
			const nome = target.name;
			const valor = target.type === 'checkbox' ? target.checked : target.value;
			const gestor = this.state.gestor;
			gestor[nome] = valor;
			this.setState({ gestor });
		}

		handleOrgaoChange(evento){
			const idOrgao = evento.target.value;
            const orgaoSelecionado = this.getOrgaoSelecionado(idOrgao);
			const gestor = this.state.gestor;
			gestor['orgao'] = idOrgao;
			gestor['orgaoCliente'] = orgaoSelecionado.isCliente;
			gestor['perfilGestor'] = '';
			gestor['vinculadoSiape'] = orgaoSelecionado.vinculadoSiape;
			gestor['atribuicao'] = '';
			gestor['apisGestor'] = [];
			this.setState({ 
				gestor,
				perfisGestor: [],
			 });
			this.carregaFornecedorasOrgao(idOrgao);
			this.carregaPerfisGestor(idOrgao);
		}

		carregaFornecedorasOrgao(idOrgao){
			if(this.props.user.isAdministrador && idOrgao && idOrgao !== ""){
				this.setState({carregandoFornecedoras : true});
				api.get(`/api/aplicacao-fornecedora/orgao/${idOrgao}`)
				.then(response => { 
					this.setState(
						{
							fornecedorasOrgao: response.data,
							carregandoFornecedoras : false
						}
					);
				})
				.catch(erro => {
					this.setState({carregandoFornecedoras : false});
					console.log("Não foi possível recuperar as aplicações fornecedoras associadas ao órgão " + JSON.stringify(erro));
					this.props.exibeMensagemErro("Erro durante o carregamento das API's do órgão.")
				});
			}
		}

		carregaPerfisGestor(idOrgao){
			if(idOrgao && idOrgao !== ""){
				this.setState({carregandoPerfisGestor : true});
				api.get(`/api/perfil-gestor/orgao/${idOrgao}`)
				.then(response => { 
					this.setState(
						{
							perfisGestor: response.data,
							carregandoPerfisGestor : false
						}
					);
				})
				.catch(erro => {
					this.setState({carregandoPerfisGestor : false});
					console.log("Não foi possível recuperar os perfis de gestor associados ao órgão ", erro);
					this.props.exibeMensagemErro("Erro durante o carregamento dos perfis de gestor compatíveis com o órgão.")
				});
			}
		}

		handlePickyChange(values){
			const fornecedorasSelecionadas = values;
			const gestor = this.state.gestor;
			gestor.apisGestor = fornecedorasSelecionadas;
			this.setState(
				{ 
					gestor,
				});
		}

		getOrgaoSelecionado(id){
			if(id){
				const orgaos = this.state.orgaos.filter((o) => o.id === Number(id));
				return orgaos[0];
			}
			return {};
		}

		isServidorSiape(){
			return this.state.gestor.orgao && this.state.gestor.vinculadoSiape === true
		}

		isNotServidorSiape(){
			return this.state.gestor.orgao && this.state.gestor.vinculadoSiape === false
		}

		atualizaGestorSiape(cpf, nome, email){
			const gestor = this.state.gestor;
			gestor['cpf'] = cpf;
			gestor['nome'] = nome;
			gestor['email'] = email;
			this.setState({gestor});
		}

		atualizaGestorNotSiape(nome, valor){
			const gestor = this.state.gestor;
			gestor[nome] = valor;
			this.setState({gestor});
		}

		novo(e){
			const rotaParaNovo = "/manager/new";
			if (rotaParaNovo === this.props.location.pathname) {
				this.props.history.push("/");
				this.props.history.goBack();
			} else {
				this.props.history.push(rotaParaNovo);
			}
		}

		salvar(e){
			this.props.limpaMensagem();

			try{
				this.validaFormulario();
			}catch(erroValidacao){
				e.preventDefault();
				return;
			}

			this.setState({botoesHabilitados:false});
			const gestor = this.state.gestor;
			const method = this.state.edicao ? 'put' : 'post';
			let url = "/api/gestor";

			const options = {
				method,
				url,
				"data" : gestor,
			}

			api(options)
				.then(response => {
					const mensagem = `Gestor ${this.state.edicao ? 'alterado' : 'incluído'} com sucesso!`;
					this.setState({ 
						gestor: response.data,
						edicao: true,
					});
					this.props.exibeMensagemSucesso(mensagem);
				})
				.catch(erro => {
					const mensagemInicial = `Não foi possível ${this.state.edicao ? 'alterar' : 'incluir'} o gestor.`;
					const msg = getMessagemErro(mensagemInicial, erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
				})
				.then (() =>{
					this.setState({botoesHabilitados:true});
				});   

		}

		validaFormulario(){
			let mensagens;
			if(this.state.gestor.vinculadoSiape === true){
				mensagens = this.validaGestorSiape();
			}else {
				mensagens = this.validaGestorNaoSiape();
			}

			if(mensagens.length > 0){
				this.props.exibeMensagemErro("Não foi possível realizar a operação porque há inconsistências nos campos informados:", mensagens)
				throw new Error("Formulário não preenchido corretamente.");
			}
		}

		validaGestorSiape(){
			let mensagens = [];
			const cpf = this.state.gestor.cpf;
			if(! cpf || cpf.trim().length === 0 ){
				mensagens.push({'mensagem' : 'Selecione o servidor através da pesquisa pelo CPF.'})
			}
			this.adicionaMensagemSeCampoNaoNumerico(cpf, mensagens, "CPF");
			
			const orgao = this.state.gestor.orgao;
			this.adicionaMensagemSeCampoNaoPreenchido(orgao, mensagens, "Órgão");

			const perfilGestor = this.state.gestor.perfilGestor;
			this.adicionaMensagemSeCampoNaoPreenchido(perfilGestor, mensagens, "Perfil do Gestor");

			return mensagens;
		}

		validaGestorNaoSiape(){
			let mensagens = [];
			const cpf = this.state.gestor.cpf;
			this.adicionaMensagemSeCampoNaoPreenchido(cpf, mensagens, "CPF");

			const orgao = this.state.gestor.orgao;
			this.adicionaMensagemSeCampoNaoPreenchido(orgao, mensagens, "Órgão");

			const perfilGestor = this.state.gestor.perfilGestor;
			this.adicionaMensagemSeCampoNaoPreenchido(perfilGestor, mensagens, "Perfil do Gestor");

			const nome = this.state.gestor.nome;
			this.adicionaMensagemSeCampoNaoPreenchido(nome, mensagens, "Nome");

			const email = this.state.gestor.email;
			this.adicionaMensagemSeCampoNaoPreenchido(email, mensagens, "Email");

			return mensagens;
		}

		adicionaMensagemSeCampoNaoPreenchido(valorPropriedade, mensagens, campoFormulario){
			if(!valorPropriedade || (typeof (valorPropriedade) == 'string' && valorPropriedade.trim().length === 0 )){
				mensagens.push({'mensagem' : campoFormulario + ' obrigatório.'})
			}
		}

		adicionaMensagemSeCampoNaoNumerico(valorPropriedade, mensagens, campoFormulario){
			if(valorPropriedade && isNaN(valorPropriedade)){
				mensagens.push({'mensagem': campoFormulario + ' deve ter formato numérico.'})
			}
		}

	}

) // redux
); // router