import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { exibeMensagemErro, limpaMensagem, mostraAguardando, escondeAguardando } from "../../providers/actions";
import api from "../../services/api";
import './Manager.css';

const mapDispatchToProps = { exibeMensagemErro, limpaMensagem, mostraAguardando, escondeAguardando }

export const ManagerEditSiape =

withRouter(

// redux
connect(
	null, 
	(mapDispatchToProps)
)(
	class extends Component {

		constructor(props){
			super(props);
			this.state = {
				cpfPesquisa: "",
				pesquisaExecutada: false,
			};
			this.handleCPFChange = this.handleCPFChange.bind(this);
			this.handleEmailChange = this.handleEmailChange.bind(this);
			this.pesquisarServidor = this.pesquisarServidor.bind(this);
		}

		render() {
			return (
				<div className="div-form">
						{this.renderForm()}
				</div>
			);
		}

		renderForm() {
			const gestor = this.props.gestor;
			return(
	
					<div className="br-form">
						{! this.props.edicao &&
						<div className="row">
							<div className="col-sd mr-1 pr-1">
								<div className="field">
									<div className="input">
										<label htmlFor="cpf">CPF:</label>
										<input type="text" name="cpfPesquisa" 
											value={this.state.cpfPesquisa}  
											onChange={this.handleCPFChange} 
											maxLength="11"/>
									</div>
								</div>
							</div>

							<div className="col-sd d-flex align-items-end ml-0 pl-0">
								<div className="actions-button">
									<div className="actions">
										<div>
											<button type="button" className="button is-secondary" onClick={this.pesquisarServidor}>Pesquisar Servidor</button>
										</div>
									</div>
								</div>
							</div>
							
						</div>
						}

						<div className="row">	
							<div className="col-sd">
								<div className="field">
									<div className="input">
										<label>CPF:</label>
										<input type="text" name="cpf" 
											value={gestor.cpf}  
											disabled={true}
											/>
									</div>
								</div>
							</div>
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label>Nome:</label>
										<input type="text" name="nome" 
											value={gestor.nome}  
											disabled={true}
											/>
									</div>
								</div>
							</div>
						</div>	
						<div className="row">
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label>Email:</label>
										<input type="text" name="email" 
											value={gestor.email} 
											onChange={this.handleEmailChange}
											/>
									</div>
								</div>
							</div>
						</div>
					</div>
				
			);
		}

		handleCPFChange(evento){
			const cpfPesquisa = evento.target.value;
			this.setState({ cpfPesquisa });
		}

		handleEmailChange(evento) {
			const gestor = this.props.gestor;
			const email = evento.target.value;
			const {nome, cpf} = gestor;
			this.atualizaServidor(cpf, nome, email, true);
		}

		pesquisarServidor(){
			this.props.limpaMensagem();
			const cpf = this.state.cpfPesquisa;
			if(cpf.length === 0){
				this.props.exibeMensagemErro("Informe o CPF do servidor.")
			}else{
				this.props.mostraAguardando();
				api.get(`/api/gestor/dados-servidor/${cpf}`)
				.then(response => { 
					const {cpf, nome, email} = response.data;
					this.atualizaServidor(cpf, nome, email, true);
				})
				.catch(erro => {
					this.atualizaServidor("", "", "", false);
					console.log("Não foi possível recuperar o servidor " + JSON.stringify(erro))
					this.props.exibeMensagemErro("Erro ao recuperar o servidor: " + erro['data']['mensagem'])
				})
				.finally(() => {
					this.props.escondeAguardando();
				});
			}
		}

		atualizaServidor(cpf, nome, email, pesquisaExecutada){
			const gestor = this.props.gestor;
			gestor['cpf'] = cpf;
			gestor['nome'] = nome;
			gestor['email'] = email;
			this.setState({gestor, pesquisaExecutada});
			this.props.onAtualizaServidor(cpf, nome, email);
		}
	
	}

) // redux
); // router