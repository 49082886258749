import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { exibeMensagemErro, limpaMensagem, mostraAguardando, escondeAguardando } from "../../providers/actions";
import api from "../../services/api";
import './Manager.css';
import "react-datepicker/dist/react-datepicker.css";
import { cpf } from 'cpf-cnpj-validator'; 

const mapDispatchToProps = { exibeMensagemErro, limpaMensagem, mostraAguardando, escondeAguardando }

export const ManagerEditNotSiape =

withRouter(

// redux
connect(
	null, 
	(mapDispatchToProps)
)(
	class extends Component {

		constructor(props){
			super(props);
			this.handleInputChange = this.handleInputChange.bind(this);
			this.atualizaPropriedadeGestor = this.atualizaPropriedadeGestor.bind(this);
			this.pesquisarDadosPessoais = this.pesquisarDadosPessoais.bind(this);
		}

		render() {
			return (
				<div className="div-form">
						{this.renderForm()}
				</div>
			);
		}

		renderForm() {
			const gestor = this.props.gestor;
			return(
					<div className="br-form">
						<div className="row">	
							<div className="col-sd mr-1 pr-1">
								<div className="field">
									<div className="input">
										<label>CPF:</label>
										<input type="text" name="cpf" maxLength="11"
											value={gestor.cpf}  
											onChange={this.handleInputChange}
											disabled={this.props.edicao}
											/>
									</div>
								</div>
							</div>
							<div className="col-sd d-flex align-items-end ml-0 pl-0">
								<div className="actions-button">
									<div className="actions">
										<div>
											<button type="button" className="button is-secondary" onClick={this.pesquisarDadosPessoais}>Pesquisar Dados Pessoais</button>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label>Nome:</label>
										<input type="text" name="nome" maxLength="100"
											value={gestor.nome} 
											onChange={this.handleInputChange} 
											/>
									</div>
								</div>
							</div>
						</div>	

						<div className="row">
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label>Email:</label>
										<input type="text" name="email" 
											value={gestor.email} maxLength="60"
											onChange={this.handleInputChange} 
											/>
									</div>
								</div>
							</div>
						</div>

					</div>
				
			);
		}

		handleInputChange(evento){
			const target = evento.target;
			const nome = target.name;
			const valor = target.type === 'checkbox' ? target.checked : target.value;
			this.atualizaPropriedadeGestor(nome, valor);
		}

		atualizaPropriedadeGestor(atributo, valorAtributo){
			const gestor = this.props.gestor;
			gestor[atributo] = valorAtributo;
			this.setState({gestor});

			this.props.onAtualizaServidor(atributo, valorAtributo);
		}

		pesquisarDadosPessoais(){
			this.props.limpaMensagem();
			const cpfInformado = this.props.gestor.cpf;
			if(!cpf.isValid(cpfInformado)){
				this.props.exibeMensagemErro("Informe um CPF válido.")
			}else{
				this.props.mostraAguardando();
				api.get(`/api/gestor/dados-pessoais/${cpfInformado}`)
				.then(response => { 
					const {Nome} = response.data;
					this.atualizaDadosPessoaisGestor(Nome);
				})
				.catch(erro => {
					this.atualizaDadosPessoaisGestor("");
					console.log("Não foi possível recuperar os dados pessoais do CPF informado ", erro)
					this.props.exibeMensagemErro("Não foi possível recuperar os dados pessoais do CPF informado: " + erro['data']['mensagem'])
				})
				.finally(() => {
					this.props.escondeAguardando();
				});
			}
		}

		atualizaDadosPessoaisGestor(Nome){
			this.atualizaPropriedadeGestor('nome', Nome);
		}
	
	}

) // redux
); // router