import React, { Component } from "react";
import { withRouter, Link} from "react-router-dom";
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem } from "../../providers/actions";
import { mostraAguardando } from "../../providers/actions";
import { Message } from "../Message/Message";
import { Loading } from "../Loading/Loading";
import {getMessagemErro} from "../../utils/erro";
import api from "../../services/api";
import { validaCamposNumericos } from "../../utils/formValidationUtil";
import './Contract.css';
import DatePicker , { registerLocale }from "react-datepicker";
import pt_br from "date-fns/locale/pt-BR";
import "react-datepicker/dist/react-datepicker.css";

const mapDispatchToProps = { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem, mostraAguardando }
registerLocale("pt_br", pt_br);

export const ContractEdit =

withRouter(

// redux
connect(
	null, 
	(mapDispatchToProps)
)(
	class extends Component {

		constructor(props){
			super(props);
			this.state = {
				contrato: {
					id: undefined,
					idTipoContrato: "",
					nomeContrato: "",
					nomeItemFaturavel: "",
					inicioVigencia: "",
					fimVigencia: "",
					informacaoComplementar: "",
					contratoSerpro: true,
					nivelServicoAcordado: "",
					nomePlataforma: "",
					valorServicoGestaoPlataforma: ""
				},
				tiposContratos: [],
				botoesHabilitados : true,
			};
			this.handleInputChange = this.handleInputChange.bind(this);
			this.handleInputNumberChange = this.handleInputNumberChange.bind(this);
			this.salvar = this.salvar.bind(this);
			this.novo = this.novo.bind(this);
		}

		render() {
			return (
				<main id="content" className="page-content p-3">
						<Message />
						<Loading />
						
						<div  style={{margin: "10px"}}>
							<h1>Cadastro de Contratos</h1>
						</div>

					<div className="div-form">
						{this.renderForm()}
					</div>
				</main>
			);
		}

		componentDidMount(){
			this.props.limpaMensagem();
			
			const id = this.props.match.params.id;

			if(id){
				api.get('/api/contrato/' + id)
				.then(response => { 
					const contrato = response.data;
					this.setState(
						{
							contrato,
						}) 
				})
				.catch(erro => {
					console.log("Não foi possível recuperar o contrato ", erro)
					this.props.exibeMensagemErro("Erro durante o carregamento da página")
				});
			}

			api.get('/api/contrato/tipos')
				.then(response => { 
					this.setState(
						{
							tiposContratos: response.data,
						}
					) 
				})
				.catch(erro => {
					console.log("Não foi possível recuperar os tipos de contrato " + erro)
					this.props.exibeMensagemErro("Erro durante o carregamento da página")
				});
		}

		renderForm() {
			const contrato = this.state.contrato;
			return(
				<div className="container-fluid">
					<div className="br-form">

						<div className="row">
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label htmlFor="idTipoContrato">Tipo de Contrato:</label>
										{ this.state.tiposContratos.length > 0 && 
										<select name="idTipoContrato" id="idTipoContrato" 
											onChange={this.handleInputChange} 
											value={contrato.idTipoContrato}
											disabled={contrato.id ? true : false} >
											<option value="">Selecione</option>
											{
												this.state.tiposContratos.map(tipoContrato =>
													<option 
														key={tipoContrato.codigo} 
														value={tipoContrato.codigo}>
														{tipoContrato.nome}
													</option>
											)}
										</select>
										}
									</div>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label htmlFor="nomeContrato">Nome:</label>
										<input type="text" id="nomeContrato" name="nomeContrato"
											value={contrato.nomeContrato}  
											onChange={this.handleInputChange} 
											maxLength="100"/>
									</div>
								</div>
							</div>	
						
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label htmlFor="nomeItemFaturavel">Item Faturável:</label>
										<input type="text" id="nomeItemFaturavel" name="nomeItemFaturavel" 
											value={contrato.nomeItemFaturavel}  
											onChange={this.handleInputChange} 
											maxLength="255"/>
									</div>
								</div>
							</div>	
						</div>

						<div className="row">
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label htmlFor="informacaoComplementar">Informação complementar (anexo):</label>
										<input type="text" id="informacaoComplementar" name="informacaoComplementar"
											value={contrato.informacaoComplementar}  
											onChange={this.handleInputChange} 
											maxLength="80"/>
									</div>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-sd">
								<div className="field">
									<div className="input">
										<label htmlFor="inicioVigencia">Início Vigência:</label>
										<DatePicker id="inicioVigencia" locale="pt_br" dateFormat="dd/MM/yyyy" 
											selected={contrato.inicioVigencia} 
											onChange={data => {
												contrato['inicioVigencia'] = data;
												this.setState({contrato})}
											} />
									</div>
								</div>
							</div>
							<div className="col-sd">
								<div className="field">
									<div className="input">
										<label htmlFor="fimVigencia">Fim Vigência:</label>
										<DatePicker id="fimVigencia" locale="pt_br" dateFormat="dd/MM/yyyy" 
											selected={contrato.fimVigencia} 
											onChange={data => {
												contrato['fimVigencia'] = data;
												this.setState({contrato})}
											} />
									</div>
								</div>
							</div>
						</div>

						{contrato.idTipoContrato === "G" &&
							<div className="row">
								<div className="col-md">
									<div className="field">
										<div className="input">
											<label>Nome da plataforma:</label>
											<input type="text" name="nomePlataforma" 
												value={contrato.nomePlataforma}  
												onChange={this.handleInputChange} 
												maxLength="50"/>
										</div>
									</div>
								</div>

								<div className="col-sd">
									<div className="field">
										<div className="input">
											<label>Nível de serviço acordado para plataforma:</label>
											<NumberFormat value={contrato.nivelServicoAcordado} 
												thousandSeparator={'.'} 
												decimalSeparator={','} 
												decimalScale={2}
												allowNegative={false}
												isNumericString={true}
												className="texto-alinhado-direita"
												onValueChange={(valoresInput) => this.handleInputNumberChange('nivelServicoAcordado', valoresInput)}
												/>
										</div>
									</div>
								</div>

								<div className="col-sd">
									<div className="field">
										<div className="input">
											<label>Valor do serviço de gestão da plataforma:</label>
											<NumberFormat value={contrato.valorServicoGestaoPlataforma} 
												thousandSeparator={'.'} 
												decimalSeparator={','} 
												decimalScale={2}
												allowNegative={false}
												isNumericString={true}
												prefix={'R$ '}
												className="texto-alinhado-direita"
												onValueChange={(valoresInput) => this.handleInputNumberChange('valorServicoGestaoPlataforma', valoresInput)}
												/>
										</div>
									</div>
								</div>
							</div>
						}

						<div className="row">
							<div className="col-sd">
								<div className="field">
									<div className="input">
										<label htmlFor="contratoSerpro">Contrato SERPRO:</label>
										<select name="contratoSerpro" value={contrato.contratoSerpro} onChange={this.handleInputChange}>
											<option value={true}>Sim</option>
											<option value={false}>Não</option>	
										</select>
									</div>
								</div>
							</div>
						</div>

						{/* button actions */}
						<div className="actions-button">
							<div className="actions justify-content-start">
								<button type="button" className="button is-primary" onClick={this.salvar} disabled={!this.state.botoesHabilitados}>{contrato.id ? 'Alterar' : 'Incluir'}</button>

								{(contrato.id && contrato.idTipoContrato === 'A') && 
									<Link to={`/contract/api-contract-details/${contrato.id}`}> 
										<div className="back-button">
											<button type="button" className="button is-secondary" disabled={!this.state.botoesHabilitados}>Informações de Faturamento das API's</button>
										</div>
									</Link>
								}

								{contrato.id && 
									<button type="button" className="button is-secondary botaoNovo" onClick={this.novo} disabled={!this.state.botoesHabilitados}>Novo Contrato</button>
								}

								<Link to="/"> 
									<div className="back-button">
										<button type="button" className="button is-secondary" disabled={!this.state.botoesHabilitados}>Voltar</button>
									</div>
								</Link>
							</div>
						</div>

					</div>
				</div>
			);
		}

		handleInputChange(evento){
			const target = evento.target;
			const nome = target.name;
			const valor = target.type === 'checkbox' ? target.checked : target.value;

			const contrato = this.state.contrato;
			contrato[nome] = valor;
			this.setState({ contrato });
		}

		handleInputNumberChange(nomePropriedade, valoresInput){
			const contrato = this.state.contrato;
			contrato[nomePropriedade] = Number(valoresInput.value);
			this.setState({ contrato });
		}

		novo(e){
			const rotaParaNovo = "/contract/new";
			if (rotaParaNovo === this.props.location.pathname) {
				this.props.history.push("/");
				this.props.history.goBack();
			} else {
				this.props.history.push(rotaParaNovo);
			}
		}

		salvar(e){
			this.props.limpaMensagem();

			try{
				this.validaFormulario();
			}catch(erroValidacao){
				e.preventDefault();
				return;
			}

			this.setState({botoesHabilitados:false});
			const contrato = this.state.contrato;
			const method = contrato.id ? 'put' : 'post';
			let url = "/api/contrato";
			if(contrato.id){
				url = url + '/' + contrato.id;
			}
			const options = {
				method,
				url,
				"data" : contrato,
			}

			api(options)
				.then(response => {
					const mensagem = `Contrato ${this.state.contrato.id ? 'alterado' : 'incluído'} com sucesso!`;
					this.setState({ 
						contrato: response.data,
					});
					this.props.exibeMensagemSucesso(mensagem);
				})
				.catch(erro => {
					const mensagemInicial = `Não foi possível ${this.state.contrato.id ? 'alterar' : 'incluir'} o contrato.`;
					const msg = getMessagemErro(mensagemInicial, erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
				})
				.then (() =>{
					this.setState({botoesHabilitados:true});
				});   

		}

		validaFormulario(){
			const LIMITE_VALOR_SERVICO_GESTAO_PLATAFORMA = 9999999999.99;

			let mensagens = [];
			const {idTipoContrato, nomeContrato, inicioVigencia, fimVigencia, nomePlataforma, nivelServicoAcordado, valorServicoGestaoPlataforma}  = this.state.contrato;

			if(!idTipoContrato || idTipoContrato.trim().length === 0 ){
				mensagens.push({mensagem: "Tipo de Contrato é obrigatório."})
			}

			if(!nomeContrato || nomeContrato.trim().length === 0 ){
				mensagens.push({mensagem: "Nome é obrigatório."})
			}

			if(!inicioVigencia){
				mensagens.push({mensagem: "Inicio da Vigência é obrigatório."})
			}

			if(!fimVigencia){
				mensagens.push({mensagem: "Fim da Vigência é obrigatório"})
			}

			if(inicioVigencia && fimVigencia && inicioVigencia > fimVigencia){
				mensagens.push({mensagem: "O Início da Vigência deve ser menor ou igual ao Fim da Vigência"})
			}

			if(idTipoContrato === "G") {
				if(!nomePlataforma || nomePlataforma.trim().length === 0 ){
					mensagens.push({mensagem: "Nome da plataforma é obrigatório."})
				}
	
				validaCamposNumericos(mensagens, nivelServicoAcordado, "Nível de serviço acordado para plataforma", true, false, false, 1);
				validaCamposNumericos(mensagens, valorServicoGestaoPlataforma, "Valor do serviço de gestão da plataforma", true, false, false, LIMITE_VALOR_SERVICO_GESTAO_PLATAFORMA);
			}

			if(mensagens.length > 0){
				this.props.exibeMensagemErro("Não foi possível realizar a operação porque há inconsistências nos campos informados:", mensagens)
				throw new Error("Formulário não preenchido corretamente.");
			}
		}

	}

) // redux
); // router